@import "./constants.scss";

.fake-loader-modal {
  width: calc(100vw - 20px);
  max-width: 750px;
}

#fake-loader {
  position: relative;
  width: 100%;
  max-width: calc(100vw - 20px);
  border: 1px solid #a02524;
  #fake-loader__progress-bar {
    padding: 20px;
    width: 0px;
    text-align: center;
    background: #a02524;
  }

  #fake-loader__progress-status {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 9px;
    color: #000;
  }
}
