@import "./mixins.scss";
@import "./sidebar.scss";
@import "./recommendation.scss";
@import "./grid.scss";
@import "./constants.scss";
@import "./fakeLoader.scss";
@import "./tags.scss";
@import "./dropdown.scss";

* {
  box-sizing: border-box;
}

$border-color: #9f2424;

$gradientStart: #48b5aa;
$gradientEnd: #273680;

html {
  scroll-behavior: smooth;
}

html,
body,
#root {
  font-family: "Roboto Condensed", sans-serif;
  width: 100%;
  height: 100%;
  position: relative;
  min-width: 320px;
}

p {
  font-family: "Roboto", sans-serif;
}

.app-title {
  letter-spacing: 0.87;
}

.app-subtitle {
  letter-spacing: 0.42;
}

.logo {
  color: #fff;
  font-style: normal;
  font-weight: 600;
}

.ReactModalPortal {
  position: relative;
  z-index: 100;
}

.font-gothic {
  font-family: "all-round-gothic";
}

.flex-2 {
  flex: 2 1 auto;
}

.red-btn {
  color: #fff;
  background-color: #9f2424;
}

.gradient-btn {
  color: #fff;
  background: transparent linear-gradient(180deg, $gradientStart 0%, $gradientEnd 100%) 0% 0%
    no-repeat padding-box;
}

.gradient-separator {
  max-width: 400px;
  margin: 40px auto 60px;
  background: #9f2424;
  height: 2px;
}

.link-finder a,
.link-finder * a {
  font-weight: bold;
  color: #9f2424;
  &:hover {
    text-decoration: underline !important;
  }
}

.font-standard *{
  font-size: 1rem !important;
}

$header-height: 60px;
$subheader-height: 70px;
$header-with-banner: 176px;

.page-content {
  max-height: calc(100vh - #{$header-height});
  min-height: calc(100vh - #{$header-height});
  @include custom-scroll-bar();
  overflow-y: auto;

  &.grid-container {
    overflow: hidden;
  }
}

.with-banner .page-content {
  max-height: calc(100vh - #{$header-with-banner});
  min-height: calc(100vh - #{$header-with-banner});
}

.main-page-container {
  min-height: calc(100vh - #{$header-height});
  max-height: auto;
  width: 100%;
  h2 {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    padding: 12px;
    color: #000;
    background: #fff;
    margin: 80px 10px 40px;
    @include breakpoint(md) {
      font-size: 20px;
      color: #fff;
      background: #9f2424;
      margin: 20px 10px;
    }
    @include breakpoint(sm) {
      font-size: 18px;
      color: #fff;
      background: #9f2424;
      margin: 20px 10px;
    }
  }
  .main-page-search-container {
    @include breakpoint(sm) {
      .async-typehead {
        margin: 10px;
        border: 1px solid #9f2424;
        border-radius: 20px;
        input {
          border-bottom: none;
          background: transparent;
          padding-left: 10px;
          padding-bottom: 5px;
        }
        .btn-search {
          right: 10px;
        }
        .btn-clear-search {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .page-content {
    min-height: calc(100vh - #{$header-height});
    max-height: calc(100vh - #{$header-height});
    width: 100%;
  }

  .with-banner .main-page-container,
  .with-banner .page-content {
    min-height: calc(100vh - #{$header-with-banner});
  }
}

.main-page-container__top {
  min-height: calc(100vh - #{$header-height} - 90px);
}

.with-banner .main-page-container__top {
  min-height: calc(100vh - #{$header-with-banner});
}

aside {
  transition: width 0.3s, height 0.3s;
}

.bg-right {
  background: url("../assets/bg-right.svg") no-repeat bottom right;
}

.bg-left {
  background: url("../assets/bg-left.svg") no-repeat bottom left;
}

input {
  &:active,
  &:focus {
    outline: none;
  }
}

.print-only {
  display: none;
}

.icon {
  color: red;
  border: none;
  background: transparent;
  font-size: 16px;
  padding: 0;

  &.search:before {
    @include font-icon("\e905");
  }

  &.menu:before {
    @include font-icon("\e90e");
  }

  &.export:before {
    @include font-icon("\e901");
  }
}

.simple-search-input {
  height: 40px;
  min-width: 400px;
  border-bottom: 1px solid #9f2424;
  padding: 5px 40px 0px 5px;

  @include breakpoint(sm) {
    min-width: 300px;
    text-overflow: ellipsis;
  }
}

header {
  background: #fff;
}

.expand-toggle {
  &:after {
    color: #9f2424;
    position: absolute;
    right: 0.5rem;
    transition: transform 0.3s ease-in-out;
    content: "▼";
  }

  &.expanded {
    &:after {
      transform: rotate(180deg);
    }
  }

  &.collapsed {
    &:after {
      transform: rotate(0deg);
    }
  }
}

.expandable-card {
  border-radius: 0.5rem;
  transition: border-radius 0.3s ease-in-out;
  background: #fff;
  color: #000;

  &.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  ~ .expandable-card__content {
    background: #fff;
    border-top: 1px solid #dbdbdd;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}

button:focus {
  outline: none;
}

.group-tabs {
  .group-tab {
    font-size: 13px;
    transition: background-color 0.2s ease-in-out;
    background-color: #2494bd;
    border: 1px solid #2494bd;

    &.current,
    &:hover {
      background-color: #f1ae16;
      border: 1px solid #f1ae16;
    }

    &.current-blue {
      background-color: #2494bd;
      border: 1px solid #2494bd;
    }

    &.group-expanded {
      background-color: #fff;
      border: none;
      border-bottom: 1px solid #eaeaea;
      color: #000;

      &:active {
        background-color: #f1ae16;
      }
    }
  }

  .group-tab-toggle {
    border-radius: 20px;
    transition: all 0.1s ease-in-out;

    &:after {
      position: absolute;
      right: 10px;
      transition: transform 0.3s ease-in-out;
      @include font-icon("\e900");
    }

    &.expanded {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;

      &:after {
        transform: rotate(180deg);
      }
    }

    &.collapsed {
      &:after {
        transform: rotate(0deg);
      }
    }
  }
}

.custom-table {
  .custom-table__label {
    border-right: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    flex: 0 0 250px;

    @include breakpoint(sm) {
      flex: 0 0 auto;
      border: none;
      font-weight: bold;
    }
  }

  &:last-child {
    .custom-table__label {
      border-bottom: none;
    }

    .custom-table__value:before {
      border-bottom: none;
    }

    @include breakpoint(sm) {
      .custom-table__value {
        border: none;
      }
    }
  }

  .custom-table__value {
    flex-grow: 1;
    position: relative;

    a {
      color: #9f2424;

      &:hover {
        color: darken(#9f2424, 10%);
      }
    }

    @include breakpoint(sm) {
      border-bottom: 1px solid $border-color;
    }

    &:before {
      position: absolute;
      content: "";
      display: inline-block;
      width: 150px;
      bottom: 0px;
      left: 0px;
      border-bottom: 1px solid $border-color;

      @include breakpoint(sm) {
        display: none;
      }
    }
  }
}

.tooltip-container {
  max-width: 400px;
}

.expandable-card__content {
  .question-data:not(:last-child) {
    $border-color: #9f2424;
    border-radius: 0;
    border-bottom: 1px solid $border-color;
  }

  .collapse-arrow {
    padding-top: 10px;
    margin-top: 20px;
    height: 30px;
    text-align: center;

    .collapse-arrow__button {
      cursor: pointer;
      padding: 10px;
      width: 30px;
      height: 30px;
      background: url("../assets/double-arrow.svg") no-repeat 50% 50%;
      background-size: 200px 30px;
      transform: rotate(90deg);
    }
  }
}

.quality-sign {
  font-family: "GRADE-quality";
  display: inline-block;
  vertical-align: center;
  margin-right: 1px;
  height: 15px;
  width: 15px;
  line-height: 15px;
  color: #dd3926;

  &.quality-sign--small {
    width: 13px;
    height: 13px;
    line-height: 13px;
  }
}

.main-page-content {
  @include custom-scroll-bar();
}

.footer {
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: center;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 1200px) {
      width: 276px;
      align-items: end;
    }

    &__title {
      @media (min-width: 1200px) {
        text-align: right;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__socials {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--texts {
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
      & .mx-auto {
        max-width: 300px;
      }
    }
  }
}

.social-link {
  display: block;
  position: relative;
  padding: 10px 10px 10px 40px;
  line-height: 30px;
  text-align: center;

  &.social-link--footer {
    border: 1px solid $border-color;
    border-radius: 50%;
    transition: all 0.3s ease-out;
    color: #fff;
    fill: #fff;
    padding: 0;
    text-align: left;
    border: none;
    background-position: center center;
    border: 1px solid #fff;
    height: 2.25rem;
    aspect-ratio: 1/1;

    fill: #fff;
    padding: 0.25rem;
    margin: 0 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    background-color: #fff;
    fill: #000;
  }
}

.facebook-link {
  width: 10px;
  height: 19px;
}

.linkedin-link {
  width: 17px;
  height: 16px;
}

.twitter-link {
  width: 18px;
  height: 14px;
}

.twitter {
  background: url("../assets/twitter_canpcc.svg") 50% 50% no-repeat, transparent;

  &:hover {
    background: url("../assets/twitter_canpcc_black.svg") 50% 50% no-repeat, #fff;
  }
}

.facebook {
  background: url("../assets/facebook_canpcc.svg") 50% 50% no-repeat, transparent;

  &:hover {
    background: url("../assets/facebook_canpcc_black.svg") 50% 50% no-repeat, #fff;
  }
}

.linkedin {
  background: url("../assets/linkedin_canpcc.svg") 50% 50% no-repeat, transparent;
  background-size: 28px 28px;

  &:hover {
    background: url("../assets/linkedin_canpcc_black.svg") 50% 50% no-repeat, #fff;
    background-size: 28px 28px;
  }
}

.btn-clear-search {
  width: 40px;
  height: 40px;
  position: absolute;
  right: -40px;
  top: 0px;
  color: #fccb5c;
  outline: none;

  &:before {
    line-height: 36px;
    font-size: 36px;
    content: "\00d7";
  }

  &:hover {
    opacity: 0.75;
  }

  &:focus {
    outline: none;
  }

  &:active {
    right: -42spx;
    top: 2px;
  }
}

.btn-search {
  width: 2rem;
  height: 3rem;
  position: absolute;
  fill: #9f2424;
  right: 0px;
  top: -3px;
  color: #000;
  outline: none;

  &:hover {
    opacity: 0.75;
  }

  &:focus {
    outline: none;
  }

  &:active {
    top: -2px;
    right: -1px;
  }
}

.separator {
  height: 1px;
  background: #b2e0e5;
}

.work-in-progress {
  background: url("../assets/gear-bg.svg") no-repeat right center;
}

.modal-content {
  margin: -20px;
  padding: 20px;
}

.close-modal {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;

  &:before {
    content: "\00d7";
    font-size: 26px;
    display: block;
    width: 10px;
    height: 10px;
    color: #666;
  }
}

.btn {
  border: 1px solid #dd3926;
  color: #dd3926;
  border-radius: 6px;
  padding: 3px 5px;
  transition: background 0.3s;

  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.75;
  }
}

.recommendations-to-compare {
  margin-top: 20px;
  max-height: calc(100vh - 200px);
  @include custom-scroll-bar();
  overflow: auto;

  .highlight-container {
    min-width: 100px;
  }

  li {
    .btn.highlight {
      color: #fff;
      background: #dd3926;
    }

    &:before {
      flex-shrink: 0;
      margin-right: 10px;
      content: " ";
      display: block;
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 50%;
    }

    &.highlighted {
      background: #fff6e1;

      &:before {
        background: #ffe333;
      }
    }
  }

  li:not(:last-child) {
    border-bottom: 1px solid #eaeaea;
  }
}

.spinner-img {
  animation: spinner-spin infinite 2s linear;
  pointer-events: none;
}

@keyframes spinner-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

nav {
  margin-left: 10px;
  display: flex;
  flex-wrap: nowrap;
  .top-nav-link {
    padding: 3px 0px;
    line-height: 16px;
    border-bottom: 2px solid transparent;

    &.active,
    &:hover {
      border-bottom: 2px white solid;
    }
  }
}

.see_more {
  font-size: 14px;
  color: $border-color;
  position: relative;
  padding-right: 25px;

  &:after {
    position: absolute;
    right: 0px;
    top: 2px;
    content: " ";
    display: inline-block;
    width: 20px;
    height: 15px;
    background: url("../assets/more-arrow.svg") 50% 50% no-repeat;
  }
}

.hamburger-menu {
  position: relative;

  button.open-menu {
    width: 35px;
    height: 18px;
    right: 8px;
    z-index: 102;
    background: url("../assets/hamburger.svg") no-repeat 0 0;

    &.open {
      background: url("../assets/hamburger-dark.svg") no-repeat 0 0;
    }
  }

  .menu-container {
    $top: 56px;
    &.open {
      height: calc(100vh - #{$top});
      width: 300px;
    }
    &.closed {
      height: 0px;
      width: 0px;
    }
    transition: height 0.3s ease-in-out;
    overflow: hidden;
    position: fixed;
    right: 0px;
    top: $top;
    background: #9f2424;
    color: #fff;
    z-index: 100;

    @include breakpoint(sm) {
      $top: 46px;
      top: $top;
      &.open {
        height: calc(100vh - #{$top});
        width: calc(100vw);
      }
      &.closed {
        height: 0px;
        width: calc(100vw);
      }
    }
  }

  .close-menu-arrow {
    width: 75px;
    height: 75px;
    margin-bottom: 20px;
    margin-left: 20px;

    &:before {
      content: " ";
      height: 100%;
      width: 100%;
      display: block;
      background: url("../assets/double-arrow.svg") 50% 50% no-repeat;
      transform: rotate(180deg);
      background-size: 75px 75px;
    }

    @include breakpoint(sm) {
      transform: rotate(270deg);
    }
  }
}

.down-arrow {
  width: 75px;
  height: 75px;
  margin-top: 5px;
  margin-bottom: 20px;

  &:before {
    content: " ";
    height: 100%;
    width: 100%;
    display: block;
    background: url("../assets/double-arrow.svg") 50% 50% no-repeat;
    background-size: 75px 75px;
    transform: rotate(270deg);
  }
}

.mobile-back-link {
  margin-right: 15px;
  $icon-size: 40px;
  display: block;
  width: $icon-size;
  height: $icon-size;
  background: url("../assets/back-button.svg") no-repeat 50% 50%;
  background-size: $icon-size $icon-size;
}

.mobile-search-icon {
  display: block;
  width: 30px;
  height: 30px;
  color: #fff;
  background: url("../assets/search-icon-white.svg") no-repeat 50% 50%;
  background-size: 30px 30px;
}

.btn-gradient,
.btn-page-back {
  color: #fff;
  background: #9f2424;
}

.strength-certainty-column {
  min-width: 260px;

  .bordered-info-box:not(:last-child) {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e5e5e5;
  }

  .info-sign {
    margin-left: auto;
    width: 40px;
    height: 40px;
    text-align: center;
    border: solid thin #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .info-sign__element {
      line-height: 1.5;
      font-size: 1rem;
    }
  }
}

.glossary-item {
  p {
    margin-bottom: 10px;
  }

  b {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
  }

  ul {
    margin: 0px 0px 10px;

    li {
      margin-left: 20px;
      list-style-type: disc;
    }
  }
}

table.glossary {
  margin-top: 30px;

  thead {
    tr {
      border-bottom: 1px solid #a02524;
    }

    th:not(:last-child) {
      border-right: 1px solid #a02524;
    }
  }

  tbody {
    tr:not(:last-child) {
      border-bottom: 1px solid #a02524;
    }
  }

  td,
  th {
    vertical-align: top;
    padding: 10px 20px;
  }

  tbody {
    td:not(:last-child) {
      border-right: 1px solid #a02524;
    }
  }
}

.isof-dx,
.isof-tx {
  margin-left: 10px;
  &.container {
    width: calc(100% - 10px) !important;
  }
  table.isof-table {
    table-layout: unset !important;
  }
}

#isof-dx-content,
#isof-tx-content {
  .isof {
    .big-number {
      font-size: 26px !important;
    }

    .close-column {
      display: none; // For hidding plain language summary
    }
  }

  .container.tables {
    margin-bottom: 0px;
    margin-top: 15px;
  }
}

.tailwind-hidden {
  display: none;
}

.conclusions-row {
  .conclusions-row__marker {
    position: relative;
    float: left;
    margin-right: 5px;

    > span {
      padding-left: 20px;
    }

    img {
      position: absolute;
      top: 4px;
      width: 15px;
      height: 15px;
    }
  }
}

.what-we-are-working-on,
.news-container {
  min-height: 200px;
  background: rgba(127, 127, 127, 0.1);
}

#twitter-testimonials {
  max-width: 1200px;
  margin: 0px auto;

  .tweet-container {
    max-width: 550px;
    margin: 0px auto;

    @include breakpoint(lg) {
      margin-right: 10px;
      width: 290px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.gradient-links-bg {
  border-top: 1px solid #fff;
  background: #fff;
}

.external-logo-link {
  flex-shrink: 0;
}

.eml-link {
  display: block;
  width: 42px;
  height: 18px;
  background: url("../assets/eml-small.svg") no-repeat 0 0;
}

.niph-link {
  display: block;
  width: 42px;
  height: 18px;
  background: url("../assets/niph-small.svg") no-repeat 0 0;
}

.love-link {
  display: block;
  width: 42px;
  height: 18px;
  background: url("../assets/love-small.svg") no-repeat 0 0;
}

.info-tooltip-icon {
  cursor: pointer;
  display: inline-block;
  position: relative;
  top: 2px;
  width: 15px;
  height: 15px;
  margin-left: 10px;
  background: url("../assets/info-icon.svg") 50% 50% no-repeat;
  background-size: 15px 15px;
}

.ReactModal__Content.ReactModal__Content--after-open.ep-modal.auto {
  position: relative;
  width: auto;
  max-width: 1000px;
}

.logos {
  img {
    min-width: 125px;
  }
}

.recommendation-page-content {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 8;
}

.section-container {
  max-width: calc(100vw - 20px);
}

.content-blocks {
  position: sticky;
  margin-top: 48px;
  top: 10px;

  .content-blocks__block {
    cursor: pointer;
    margin-bottom: 8px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.additional-guidance-search-container {
  max-width: 25.5rem;
}

.select-sign-standalone {
  transition: all 0.3s ease-in-out;
  display: inline-block;
  &:after {
    content: "▼";
  }
  &.open {
    transform: rotate(180deg);
  }
  &.closed {
    transform: rotate(0deg);
  }
}

.options-select-container.language-switcher {
  color: #fff;
  padding: 1px 2px 0px 5px;
  outline: none;
  height: 100%;
  line-height: 24px;
  cursor: pointer;

  .select-input {
    line-height: 24px;

    .text-box {
      padding: 0px;
    }
  }

  .select-sign {
    padding: 0px;
    margin: 0px;

    &:before {
      color: #fff;
    }
  }

  &.showing-options {
    color: #000;
    background: #fff;

    & .select-sign::before {
      color: #000;
    }
  }
}

.PopupBox_Content {
  background: transparent;
  & * {
    border-color: #9f2424;
  }
}

.language-switcher__list {
  border: 1px solid #9f2424;
  border-radius: 5px;
  background: #fff;
  li {
    padding: 5px;
    cursor: pointer;
    color: #000;
    &:before {
      position: relative;
      top: 0px;
      content: " ";
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: 6px;
      border-radius: 100%;
      border: 1px solid #9f2424;
      background-color: #fff;
    }
    &.selected,
    &:hover {
      &:before {
        background-color: #9f2424;
      }
    }
  }
}

.our-page-in-numbers {
  background: #efefef;

  text {
    fill: black;
  }
}

.bubble-container {
  text-align: center;
  color: #fff;
  font-size: 13px;
  margin: 0 30px;
  width: 150px;
  position: relative;

  h1 {
    font-size: 40px;
    line-height: 20px;

    span {
      font-family: "Roboto Condensed";
      font-weight: 500;
      text-transform: uppercase;
      margin-top: 10px;
      line-height: 13px;
      font-size: 13px;
      display: block;
    }
  }
}

.link-red,
.text-red {
  color: #9f2424;
}

.bubble {
  position: relative;
  top: 0px;
  aspect-ratio: 1/1;
  height: 100%;
  padding: 1rem;
  border: 1px solid #9f2424;
  border-radius: 50%;
  background: #9f2424;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label-transformed {
  text-align: center;
  text-transform: uppercase;
  padding: 2px 5px;
  display: inline-block;
  background-color: #eaeaea;
  color: #707070;
  font-size: 10px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
}

.webinar-info {
  text-align: center;
  background: #fadad6;
  color: #000;
  font-weight: bold;
  padding: 15px;

  a:hover {
    text-decoration: underline;
  }
}

.grading-table tr {
  td,
  th {
    &.empty {
      left: -1px;
      position: relative;
      background: #fff;
      border: 1px solid #fff !important;
    }
  }
}

.team-table {
  .cell {
    border-right: 1px solid #009cca;
    border-bottom: 1px solid #009cca;

    &:last-child {
      border-right: none;
    }
  }
}

.tile {
  border: 1px solid #fff;
  padding: 20px;

  &.links {
    @media (min-width: 1388px) and (max-width: 1850px) {
      display: block;
      width: 100%;
      max-width: 1445px;

      > div {
        display: block;
        width: 100%;
        max-width: 100%;
        padding: 20px;
      }

      .links-container {
        display: block;
        width: 100%;

        > div {
          display: flex;
          flex-direction: row;
          flex-grow: 1;
          width: 100%;
        }

        .link {
          align-self: stretch;
          flex: 1 0 0;
          margin-left: 30px;
        }
      }
    }
  }
}

.newsletter-modal-container {
  min-height: 250px;
  position: relative;

  .background {
    $size: 300px;
    z-index: 1;
    opacity: 0.15;
    position: absolute;
    top: 50px;
    left: 0px;
    background: url("../assets/COVID_gradient.svg") right bottom no-repeat;
    background-size: $size $size;
    height: $size;
    width: $size;
  }

  .newsletter-content {
    position: relative;
    z-index: 2;
  }

  .newsletter-title {
    font-size: 22px;
    max-width: 500px;
    text-align: center;
    line-height: 28px;
    font-weight: 600;
    margin: 30px auto 50px;
  }
}

.async-typehead {
  position: relative;
  margin: 10px 30px 10px 0px;
  max-width: 600px;
  .btn-close.rbt-close {
    position: absolute;
    right: 25px;
    top: 5px;
  }

  .dropdown-menu {
    background-color: #fff;
    border: 1px solid #9f2424;
    border-top: none;
    z-index: 10;
  }

  .suggestion-input {
    height: 40px;
    min-width: 450px;
    border-bottom: 1px solid #dbdbdd;
    padding: 5px 40px 0px 5px;

    @include breakpoint(sm) {
      min-width: calc(100vw - 40px);
      text-overflow: ellipsis;
    }

    &::placeholder {
      color: #898989;
      @include breakpoint(sm) {
        font-size: 12px;
      }
    }
  }

  .dropdown-item {
    display: block;
    background-color: #fff;
    margin-bottom: 5px;
    padding: 5px;
    width: 100%;
    overflow-x: hidden;
    word-wrap: break-word;

    &.active,
    &:hover {
      background: #eaeaea;
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
}

.instruction-modal-content {
  max-width: 960px;
  max-height: calc(100vh - 200px);
  overflow: auto;
  @include custom-scroll-bar();
}

#resizable-container {
  overflow: auto;
  @include custom-scroll-bar();
}

.language-selector {
  margin-left: 20px;
  background-color: #fff;
  outline: none;
  border-bottom: 1px solid #9f2424;
  padding: 10px;

  option {
    background-color: #fff;
  }
}

.plr-list {
  .arabic {
    direction: rtl;
  }
}

.kb-content {
  padding: 20px 25px;

  ul,
  ol {
    margin: 15px 0;
    padding: 0 0 0 40px;
    line-height: 1.8rem;
  }

  ol {
    list-style-type: decimal;
  }
}

.navigation {
  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
    justify-items: center;
  }

  & .flex-auto:hover {
    background-color: #fff;
    color: #222;
  }
}

.mobile-nav-trigger {
  height: 3rem;

  @media (min-width: 992px) {
    display: none;
  }
}

.bold {
  font-weight: 700;
}

.weight-400 {
  font-weight: 400;
}

.header-borders {
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
}

.video-player {
  padding: 0 5vw;

  iframe {
    aspect-ratio: 16/9;
  }
}

.search-header-bar {
  font-size: 20px;
  padding: 10px 25px;
  border: 1px solid #9f2424;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
}

.topic-image-container {
  width: 190px;
}

.editor-content {
  ul {
    list-style-type: disc;
    margin-left: 24px;
    margin-top: 12px;
  }
}
